import React, { Component } from "react";
import "./App.css";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import store from "./state/store";
import { Provider as ReduxProvider } from "react-redux";
import Toaster from "./components/alerts/Toaster";
import Home from "./components/home/Home";
import LandingPage from "./components/layout/LandingPage";
import router from "./routes";

export default class App extends Component {
    render() {
        const { hostname } = window.location;
        const isAppSubdomain = hostname.startsWith("app.");

        const customRouter = createBrowserRouter([
            {
                id: "home-or-landing",
                path: "/",
                element: isAppSubdomain ? <Home /> : <LandingPage />,
            },
            ...router.routes.map((route, index) => ({ ...route, id: `route-${index}` })),
        ]);

        return (
            <ReduxProvider store={store}>
                <Toaster />
                <RouterProvider router={customRouter}>
                    <div id="wrapper">
                        <Outlet />
                    </div>
                </RouterProvider>
            </ReduxProvider>
        );
    }
}