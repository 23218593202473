import React, { useState } from 'react';
import Env from "../../services/Env";

const LandingPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); // For user feedback
    const [loading, setLoading] = useState(false); // To handle button loading state

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${Env.serverURL}/api/v1/users/subscribe`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            const result = await response.json();
            if (result.success) {
                setMessage('Thank you for subscribing! Please check your email.');
            } else {
                setMessage('Oops! Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting email:', error);
            setMessage('Unable to subscribe at the moment. Please try again later.');
        } finally {
            setLoading(false);
            setEmail('');
        }
    };

    return (
        <div className="landing-page d-flex flex-column min-vh-100">
            <header className="text-white text-center py-5 mt-5 header-larger">
                <div className="container">
                    <h1 className="display-4">Welcome to Bundlr</h1>
                    <p className="lead">Simplify your team's productivity with Bundlr's intuitive workspaces.</p>
                </div>
            </header>

            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="card shadow">
                                <div className="card-body p-5">
                                    <h3 className="card-title text-center mb-4">Stay Updated!</h3>
                                    <p className="text-center">Sign up with your email to get early access to Bundlr.</p>
                                    {message && <p className="text-center text-success">{message}</p>}
                                    <form onSubmit={handleEmailSubmit} className="d-flex justify-content-center">
                                        <input
                                            type="email"
                                            className="form-control form-control-lg me-3"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            disabled={loading}
                                        />
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg"
                                            disabled={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Subscribe'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="text-white text-center py-3 mt-auto">
                <div className="container">
                    <p>&copy; {new Date().getFullYear()} Bundlr Inc. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
